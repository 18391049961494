import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import './PreRegister.css';
import logo from '../moveouttr.png';
import SEO from './SEO';
import { sanitizeInput, validateForm } from '../utils/validation';
import { supabase } from '../config/supabaseClient';
import useRateLimit from '../hooks/useRateLimit';
import companyIcon from '../assets/company.png';
import wheelIcon from '../assets/wheel.png';
import ad2 from '../assets/ad2.png';
import boxDimensions from '../assets/boxdimensions.png';
import boxes from '../assets/boxes.png';
import cab from '../assets/cab.jpg';

const useTypewriter = (text, speed = 100) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    // Reset when text changes (language changes)
    setDisplayText('');
    setCurrentIndex(0);
    setIsDeleting(false);
    setIsPaused(false);
  }, [text]);

  useEffect(() => {
    let timer;

    if (isDeleting) {
      if (displayText === '') {
        setIsDeleting(false);
        setIsPaused(false);
        setCurrentIndex(0); // Reset index to start over
        return;
      }

      timer = setTimeout(() => {
        setDisplayText(prev => prev.slice(0, -1));
      }, speed / 2);
    } else if (!isPaused) {
      if (currentIndex < text.length) {
        timer = setTimeout(() => {
          setDisplayText(prev => prev + text[currentIndex]);
          setCurrentIndex(prev => prev + 1);
        }, speed);
      } else {
        setIsPaused(true);
        timer = setTimeout(() => {
          setIsDeleting(true);
        }, 2000); // Wait 2 seconds before deleting
      }
    }

    return () => clearTimeout(timer);
  }, [currentIndex, text, speed, isDeleting, isPaused, displayText]);

  return displayText;
};

// Add a new TypingEffect component
const TypingEffect = ({ text, speed = 100 }) => {
  const displayText = useTypewriter(text, speed);
  return (
    <h2 className="typing-title">
      {displayText}
      <span className="cursor">|</span>
    </h2>
  );
};

// Add PrivacyPolicyModal component
const PrivacyPolicyModal = ({ isOpen, onClose, currentLang }) => {
  const content = {
    tr: {
      title: "Gizlilik Politikası",
      content: `
        1. Veri Toplama ve Kullanım
        Kişisel verileriniz (ad, e-posta, telefon) yalnızca moveout.app hizmetlerini sağlamak ve geliştirmek için kullanılır.

        2. Veri Güvenliği
        Verileriniz endüstri standardı güvenlik önlemleriyle korunmaktadır.

        3. Veri Paylaşımı
        Verileriniz yasal zorunluluklar dışında üçüncü taraflarla paylaşılmaz.

        4. Çerezler
        Hizmet kalitesini artırmak için çerezler kullanılmaktadır.

        5. Haklarınız
        Verilerinize erişim, düzeltme ve silme haklarına sahipsiniz.

        6. İletişim
        Gizlilik politikamızla ilgili sorularınız için hello@moveout.app adresinden bize ulaşabilirsiniz.
      `,
      close: "Kapat"
    },
    en: {
      title: "Privacy Policy",
      content: `
        1. Data Collection and Usage
        Your personal data (name, email, phone) is only used to provide and improve moveout.app services.

        2. Data Security
        Your data is protected with industry-standard security measures.

        3. Data Sharing
        Your data is not shared with third parties except for legal requirements.

        4. Cookies
        Cookies are used to improve service quality.

        5. Your Rights
        You have rights to access, correct, and delete your data.

        6. Contact
        For questions about our privacy policy, you can reach us at hello@moveout.app.
      `,
      close: "Close"
    }
  };

  if (!isOpen) return null;

  return (
    <div className="privacy-policy-modal-overlay" onClick={onClose}>
      <div className="privacy-policy-modal" onClick={e => e.stopPropagation()}>
        <h2>{content[currentLang].title}</h2>
        <div className="privacy-policy-content">
          <pre>{content[currentLang].content}</pre>
        </div>
        <button className="close-button" onClick={onClose}>
          {content[currentLang].close}
        </button>
      </div>
    </div>
  );
};

const PreRegister = ({ currentLang, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    userType: 'individual', // individual or business
    userRole: 'sender', // sender or driver
    notificationPreference: 'email',
    privacyPolicyAccepted: false,
    captchaToken: '',
    driverRequirements: {
      commercialVehicle: false,
      src4Certificate: false,
      psychotechnicalCertificate: false,
      companyRegistration: false,
      criminalRecord: false
    }
  });

  const [currentSlide, setCurrentSlide] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitAttempts, setSubmitAttempts] = useState(0);
  const { canSubmit, resetLimit } = useRateLimit(3, 60000); // 3 attempts per minute
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);

  const captchaRef = useRef(null);

  const translations = {
    tr: {
      title: "Ön Kayıt",
      subtitle: "moveout.app'i ilk kullananlardan olun veya yeniliklerden haberdar olun",
      name: "Ad Soyad",
      email: "E-posta",
      phone: "Telefon",
      userType: "Kullanıcı tipi",
      individual: "Bireysel",
      business: "Kurumsal",
      userRole: "Hizmet tipi",
      sender: "Gönderi yapacağım",
      driver: "Araç sahibiyim",
      driverRequirements: {
        title: "Gerekli Şartlar:",
        requirements: [
          "Ticari bir araç",
          "SRC4 Belgesi",
          "Psikoteknik Belgesi",
          "Vergi Kimlik Numarası / Şirket Bilgileri",
          "Temiz Adli Sicil Kaydı Belgesi"
        ]
      },
      notificationPreference: "Bildirim tercihi",
      emailNotif: "E-posta",  
      smsNotif: "SMS",
      bothNotif: "Her ikisi",
      submit: "Kayıt Ol",
      success: "Ön kayıt işleminiz başarıyla tamamlandı!",
      error: "Bir hata oluştu. Lütfen tekrar deneyin.",
      description: "Uygulamamız yakında yayında! Ön kayıt yaptırarak moveout.app'in sunduğu avantajlardan ilk siz yararlanın.",
      close: "Kapat",
      namePlaceholder: "Ad ve soyadınız",
      emailPlaceholder: "ornek@sirket.com",
      phonePlaceholder: "Telefon numaranız",
      privacyPolicy: "Gizlilik politikasını okudum ve kabul ediyorum.",
      privacyPolicyError: "Devam etmek için gizlilik politikasını kabul etmelisiniz.",
      captchaError: "Lütfen robot olmadığınızı doğrulayın.",
      generalError: "Bir hata oluştu. Lütfen tüm alanları kontrol edip tekrar deneyin."
    },
    en: {
      title: "Pre-Register",
      subtitle: "Be among the first to use moveout.app",
      name: "Full Name",
      email: "Email",
      phone: "Phone",
      userType: "User Type",
      individual: "Individual",
      business: "Business",
      userRole: "Service Type",
      sender: "I'll Send Shipments",
      driver: "I Have a Vehicle",
      driverRequirements: {
        title: "Required Conditions:",
        requirements: [
          "Commercial vehicle",
          "SRC4 Certificate",
          "Psychotechnical Certificate",
          "Company registration"
        ]
      },
      notificationPreference: "Notification Preference",
      emailNotif: "Email",
      smsNotif: "SMS",
      bothNotif: "Both",
      submit: "Register",
      success: "Pre-registration completed successfully!",
      error: "An error occurred. Please try again.",
      description: "Our app is coming soon! Pre-register now to be among the first to benefit from moveout.app's advantages.",
      close: "Close",
      namePlaceholder: "Your full name",
      emailPlaceholder: "example@company.com",
      phonePlaceholder: "Your phone number",
      privacyPolicy: "I have read and accept the privacy policy.",
      privacyPolicyError: "You must accept the privacy policy to continue.",
      captchaError: "Please verify that you are not a robot.",
      generalError: "An error occurred. Please check all fields and try again."
    }
  };

  const rightSideContent = {
    tr: {
      title: [
        "Hoş geldiniz",
        "Sevkiyatlarınızı akıllıca yönetin.",
        "İadelerinizi akıllıca yönetin.",
        "Maliyetlerinizi düşürün.",
        "Sürdürülebilir bir gelecek için.",
        "Ek gelir elde edin."
      ],
      description: "Süreçlerinizi akıllıca yönetin, giderlerinizi azaltın ve daha sürdürülebilir lojistik için harekete geçin!"
    },
    en: {
      title: [
        "Welcome to logistics world",
        "Optimize your shipments",
        "Manage your returns",
        "Reduce your costs",
        "For a sustainable future",
        "Earn extra income"
      ],
      description: "Optimize your shipments, reduce costs, and take a step towards a sustainable future with moveout.app"
    }
  };

  const t = translations[currentLang];

  const slides = {
    tr: [
      {
        image: ad2,
        title: "Kolay Taşımacılık",
        description: "Eşyalarınızı güvenle taşıyın"
      },
      {
        image: boxDimensions,
        title: "Akıllı Paketleme",
        description: "Boyutlara göre optimum taşıma"
      },
      {
        image: boxes,
        title: "Güvenli Teslimat",
        description: "Eşyalarınız güvende"
      },
      {
        image: cab,
        title: "Profesyonel Sürücüler",
        description: "Deneyimli ekip"
      }
    ],
    en: [
      {
        image: ad2,
        title: "Easy Transportation",
        description: "Transport your items safely"
      },
      {
        image: boxDimensions,
        title: "Smart Packaging",
        description: "Optimal transport based on dimensions"
      },
      {
        image: boxes,
        title: "Secure Delivery",
        description: "Your items are safe"
      },
      {
        image: cab,
        title: "Professional Drivers",
        description: "Experienced team"
      }
    ]
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prev => (prev + 1) % slides[currentLang].length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentLang]);

  useEffect(() => {
    const titleInterval = setInterval(() => {
      setCurrentTitleIndex(prev => 
        (prev + 1) % rightSideContent[currentLang].title.length
      );
    }, 6000); // Change title every 6 seconds (typing + pause + deletion)
    
    return () => clearInterval(titleInterval);
  }, [currentLang]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    const sanitizedValue = sanitizeInput(value);
    
    setFormData(prevState => {
      const newState = { ...prevState, [name]: sanitizedValue };
      
      // If changing from business to individual, and current role is driver,
      // reset role to sender
      if (name === 'userType' && value === 'individual' && prevState.userRole === 'driver') {
        newState.userRole = 'sender';
      }
      
      return newState;
    });

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  }, [errors]);

  const handleRequirementChange = (requirement) => {
    setFormData(prev => ({
      ...prev,
      driverRequirements: {
        ...prev.driverRequirements,
        [requirement]: !prev.driverRequirements[requirement]
      }
    }));
  };

  const handleCaptchaVerify = (token) => {
    setFormData(prev => ({ ...prev, captchaToken: token }));
    setErrors(prev => ({ ...prev, captcha: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!canSubmit) {
      setErrors(prev => ({
        ...prev,
        submit: t.tooManyAttempts || 'Too many attempts. Please try again later.'
      }));
      return;
    }

    if (!formData.privacyPolicyAccepted) {
      setErrors(prev => ({
        ...prev,
        privacyPolicy: t.privacyPolicyError
      }));
      return;
    }

    if (!formData.captchaToken) {
      setErrors(prev => ({
        ...prev,
        captcha: t.captchaError
      }));
      return;
    }

    setSubmitAttempts(prev => prev + 1);
    setErrors({});
    
    // Validate form
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    
    try {
      // Convert form data to match database column names
      const dbFormData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        user_type: formData.userType,
        user_role: formData.userRole,
        notification_preference: formData.notificationPreference,
        commercial_vehicle: formData.userRole === 'driver' ? formData.driverRequirements.commercialVehicle : false,
        src4_certificate: formData.userRole === 'driver' ? formData.driverRequirements.src4Certificate : false,
        psychotechnical_certificate: formData.userRole === 'driver' ? formData.driverRequirements.psychotechnicalCertificate : false,
        company_registration: formData.userRole === 'driver' ? formData.driverRequirements.companyRegistration : false,
        criminal_record: formData.userRole === 'driver' ? !formData.driverRequirements.criminalRecord : null
      };

      // Debug logging
      console.log('Submitting form data:', dbFormData);
      console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL);

      // Insert into pre_register_forms table using Supabase client
      const { data, error } = await supabase
        .from('pre_register_forms')
        .insert([dbFormData])
        .select();

      if (error) {
        console.error('Supabase error details:', error);
        throw error;
      }

      console.log('Submission successful:', data);

      setSubmitted(true);
      resetLimit();
      
      // Track successful registration
      if (window.gtag) {
        window.gtag('event', 'pre_register', {
          'event_category': 'engagement',
          'event_label': formData.userType
        });
      }
    } catch (err) {
      console.error('Pre-registration error:', err);
      setErrors(prev => ({
        ...prev,
        submit: err.message || t.generalError
      }));
      // Reset captcha on error
      if (captchaRef.current) {
        captchaRef.current.resetCaptcha();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      setFormData({
        name: '',
        email: '',
        phone: '',
        userType: 'individual',
        userRole: 'sender',
        notificationPreference: 'email',
        privacyPolicyAccepted: false,
        captchaToken: '',
        driverRequirements: {
          commercialVehicle: false,
          src4Certificate: false,
          psychotechnicalCertificate: false,
          companyRegistration: false,
          criminalRecord: false
        }
      });
      setErrors({});
    };
  }, []);

  // Accessibility improvements
  useEffect(() => {
    const dialog = document.querySelector('.pre-register-container');
    if (dialog) {
      dialog.setAttribute('role', 'dialog');
      dialog.setAttribute('aria-modal', 'true');
      dialog.setAttribute('aria-labelledby', 'pre-register-title');
    }
  }, []);

  return (
    <div className={`pre-register-container ${isClosing ? 'slide-out' : ''}`}>
      <SEO 
        title={t.title}
        description="Pre-register for Moveout - Be among the first to experience the future of logistics. Join our platform for smart shipping and sustainable transportation solutions."
        keywords="pre-registration, early access, logistics platform, shipping solutions, sustainable logistics"
        url="/pre-register"
        lang={currentLang}
        type="website"
      />
      <button onClick={handleClose} className="back-button">
        <img 
          src={require('../assets/back.png')} 
          alt="Back" 
          className="back-icon"
        />
      </button>

      <div className="pre-register-content">
        <div className="pre-register-left">
          <div className="pre-register-header">
            <img src={logo} alt="Logo" className="pre-register-logo" />
            <h1>{t.title}</h1>
            <p>{t.subtitle}</p>
          </div>

          {submitted ? (
            <div className="success-message">
              <h3>{t.success}</h3>
              <p>{t.description}</p>
              <button 
                onClick={handleClose}
                className="submit-button"
              >
                {t.close}
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="pre-register-form">
              <div className="form-group" style={{"--index": 1}}>
                <label htmlFor="name">{t.name}</label>
                <div className="input-with-icon">
                  <svg className="input-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                    <circle cx="12" cy="7" r="4"/>
                  </svg>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={t.namePlaceholder}
                    required
                    minLength={2}
                    maxLength={50}
                    pattern=".*"
                  />
                </div>
              </div>

              <div className="form-group" style={{"--index": 2}}>
                <label htmlFor="email">{t.email}</label>
                <div className="input-with-icon">
                  <svg className="input-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm-2 3l-6 5-6-5"/>
                  </svg>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder={t.emailPlaceholder}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  />
                </div>
              </div>

              <div className="form-group" style={{"--index": 3}}>
                <label htmlFor="phone">{t.phone}</label>
                <div className="input-with-icon">
                  <svg className="input-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"/>
                  </svg>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder={t.phonePlaceholder}
                    required
                    pattern="[0-9+\-\s]+"
                    minLength={10}
                    maxLength={15}
                  />
                </div>
              </div>

              <div className="form-group" style={{"--index": 4}}>
                <label>{t.userType}</label>
                <div className="radio-group">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="userType"
                      value="individual"
                      checked={formData.userType === 'individual'}
                      onChange={handleChange}
                    />
                    <div className="radio-content">
                      <div className="radio-icon">
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                          <circle cx="12" cy="7" r="4"/>
                        </svg>
                      </div>
                      <span className="radio-text">{t.individual}</span>
                    </div>
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="userType"
                      value="business"
                      checked={formData.userType === 'business'}
                      onChange={handleChange}
                    />
                    <div className="radio-content">
                      <div className="radio-icon">
                        <img src={companyIcon} alt="" className="custom-icon" />
                      </div>
                      <span className="radio-text">{t.business}</span>
                    </div>
                  </label>
                </div>
              </div>

              <div className="form-group" style={{"--index": 5}}>
                <label>{t.userRole}</label>
                <div className="radio-group">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="userRole"
                      value="sender"
                      checked={formData.userRole === 'sender'}
                      onChange={handleChange}
                    />
                    <div className="radio-content">
                      <div className="radio-icon">
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                          <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/>
                          <path d="M3.3 7l8.7 5 8.7-5"/>
                          <path d="M12 22V12"/>
                        </svg>
                      </div>
                      <span className="radio-text">{t.sender}</span>
                    </div>
                  </label>
                  <label className={`radio-label ${formData.userType === 'individual' ? 'disabled' : ''}`}>
                    <input
                      type="radio"
                      name="userRole"
                      value="driver"
                      checked={formData.userRole === 'driver'}
                      onChange={handleChange}
                      disabled={formData.userType === 'individual'}
                    />
                    <div className="radio-content">
                      <div className="radio-icon">
                        <img src={wheelIcon} alt="" className="custom-icon" />
                      </div>
                      <span className="radio-text">{t.driver}</span>
                    </div>
                  </label>
                </div>
                {formData.userRole === 'driver' && formData.userType === 'business' && (
                  <div className="driver-requirements">
                    <h4>{t.driverRequirements.title}</h4>
                    <ul>
                      {t.driverRequirements.requirements.map((requirement, index) => {
                        const requirementKey = [
                          'commercialVehicle',
                          'src4Certificate',
                          'psychotechnicalCertificate',
                          'companyRegistration',
                          'criminalRecord'
                        ][index];
                        
                        return (
                          <li 
                            key={index}
                            className={`requirement-item ${formData.driverRequirements[requirementKey] ? 'checked' : ''}`}
                            onClick={() => handleRequirementChange(requirementKey)}
                          >
                            <div className="requirement-checkbox">
                              {formData.driverRequirements[requirementKey] ? (
                                <svg className="check-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                  <path d="M20 6L9 17l-5-5"/>
                                </svg>
                              ) : (
                                <div className="empty-checkbox" />
                              )}
                            </div>
                            <span className="requirement-text">{requirement}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>

              <div className="form-group" style={{"--index": 6}}>
                <label>{t.notificationPreference}</label>
                <div className="input-with-icon">
                  <svg className="input-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0"/>
                  </svg>
                  <select
                    name="notificationPreference"
                    value={formData.notificationPreference}
                    onChange={handleChange}
                    className="styled-select"
                  >
                    <option value="email">{t.emailNotif}</option>
                    <option value="sms">{t.smsNotif}</option>
                    <option value="both">{t.bothNotif}</option>
                  </select>
                </div>
              </div>

              <div className="form-group privacy-policy-group" style={{"--index": 7}}>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="privacyPolicyAccepted"
                    checked={formData.privacyPolicyAccepted}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: 'privacyPolicyAccepted',
                          value: e.target.checked
                        }
                      });
                    }}
                  />
                  <span className="checkbox-text">
                    {t.privacyPolicy}{' '}
                    <button 
                      type="button" 
                      className="privacy-policy-link"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsPrivacyPolicyOpen(true);
                      }}
                    >
                      ({t.footer?.privacy || 'Privacy Policy'})
                    </button>
                  </span>
                </label>
                {errors.privacyPolicy && (
                  <div className="error-message">
                    {errors.privacyPolicy}
                  </div>
                )}
              </div>

              <div className="form-group captcha-group" style={{"--index": 7}}>
                <HCaptcha
                  ref={captchaRef}
                  sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                  onVerify={handleCaptchaVerify}
                  onExpire={() => {
                    setFormData(prev => ({ ...prev, captchaToken: '' }));
                    setErrors(prev => ({ ...prev, captcha: t.captchaError }));
                  }}
                />
                {errors.captcha && (
                  <div className="error-message">
                    {errors.captcha}
                  </div>
                )}
              </div>

              {Object.keys(errors).length > 0 && (
                <div 
                  className="error-message" 
                  role="alert"
                  aria-live="polite"
                >
                  {Object.values(errors).map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              )}
              
              <div className="form-group full-width" style={{"--index": 8}}>
                <button 
                  type="submit" 
                  className={`submit-button ${isLoading ? 'loading' : ''}`}
                  disabled={isLoading || !canSubmit}
                  aria-busy={isLoading}
                >
                  {isLoading ? (
                    <div className="spinner" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : t.submit}
                </button>
              </div>
            </form>
          )}
        </div>

        <div className="pre-register-right">
          <div className="pre-register-right-content">
            <TypingEffect 
              text={rightSideContent[currentLang].title[currentTitleIndex]} 
              speed={100} 
            />
            <p>{rightSideContent[currentLang].description}</p>
          </div>
        </div>
      </div>

      {/* Add the PrivacyPolicyModal component */}
      <PrivacyPolicyModal
        isOpen={isPrivacyPolicyOpen}
        onClose={() => setIsPrivacyPolicyOpen(false)}
        currentLang={currentLang}
      />
    </div>
  );
};

PreRegister.propTypes = {
  currentLang: PropTypes.oneOf(['en', 'tr']).isRequired,
  onClose: PropTypes.func.isRequired
};

export default React.memo(PreRegister); 
import React from 'react';
import './Dashboard.css'; // Import the CSS for Dashboard
import logo from '../moveouttr.png';

const Dashboard = () => {
    return (
        <div className="dashboard-page">
            <div className="dashboard-header">
                <img src={logo} alt="Logo" className="dashboard-logo" />
                <h1>Admin Dashboard</h1>
            </div>
            <div className="dashboard-content">
                <h2>Welcome to the Admin Dashboard</h2>
                <p>Here you can manage users, view reports, and configure settings.</p>
                {/* Add more dashboard functionalities here */}
            </div>
        </div>
    );
};

export default Dashboard; 
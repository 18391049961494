import React, { useState, useEffect } from 'react';
import './Admin.css'; // Import the CSS for Admin
import logo from '../moveouttr.png';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const Admin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // Authenticate with Supabase
        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: username,
                password: password,
            });
            if (error) throw error;
            if (data.user) {
                navigate('/admin/dashboard'); // Redirect to admin dashboard
            }
        } catch (error) {
            alert(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        // Validate email
        if (!userEmail) {
            alert('Email is required');
            return;
        }
        setIsLoading(true);
        // Add user to Supabase
        try {
            const { data, error } = await supabase
                .from('users')
                .insert([{ email: userEmail }]);
            if (error) throw error;
            alert('User added successfully!');
            setUserEmail(''); // Clear input
        } catch (error) {
            alert(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="admin-page">
            <div className="admin-content">
                <div className="admin-left">
                    <div className="admin-header">
                        <img src={logo} alt="Logo" className="admin-logo" />
                        <h1>Admin Panel</h1>
                        <p>Manage your application settings and user data.</p>
                    </div>
                    <form onSubmit={handleLogin} className="admin-login-form">
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className={`login-btn ${isLoading ? 'loading' : ''}`} disabled={isLoading}>
                            {isLoading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>
                    <form onSubmit={handleAddUser} className="admin-add-user-form">
                        <div className="form-group">
                            <label htmlFor="userEmail">Add User Email</label>
                            <input
                                type="email"
                                id="userEmail"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className={`add-user-btn ${isLoading ? 'loading' : ''}`} disabled={isLoading}>
                            {isLoading ? 'Adding...' : 'Add User'}
                        </button>
                    </form>
                </div>
                <div className="admin-right">
                    <div className="admin-image"></div>
                    <div className="admin-right-content">
                        <h2>Welcome to the Admin Panel</h2>
                        <p>Here you can manage all aspects of the application.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Admin; 
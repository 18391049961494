import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const ProtectedRoute = ({ element, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUser = async () => {
            const { data: { user }, error } = await supabase.auth.getUser();
            if (user) {
                setIsAuthenticated(true);
                // Check user role
                const { data: { role } } = await supabase.from('users').select('role').eq('id', user.id).single();
                if (role === 'admin') {
                    return <Navigate to="/admin/dashboard" />;
                }
            }
            if (error) {
                setLoading(false);
                return <Navigate to="/login" />;
            }
            setLoading(false);
        };
        checkUser();
    }, []);

    if (loading) return <div>Loading...</div>;

    return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute; 
import React, { useEffect, useRef } from 'react';
import finishIcon from '../assets/finish.png';
import startIcon from '../assets/carry.png';

const MapView = ({ startLocation, endLocation }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loadMapScript = () => {
      return new Promise((resolve, reject) => {
        const existingScript = document.getElementById('google-maps-script');
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,directions`;
          script.id = 'google-maps-script';
          script.onload = () => resolve();
          script.onerror = () => reject(new Error('Google Maps script failed to load.'));
          document.body.appendChild(script);
        } else {
          resolve();
        }
      });
    };

    loadMapScript()
      .then(() => {
        if (window.google) {
          const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: (startLocation.latitude + endLocation.latitude) / 2, lng: (startLocation.longitude + endLocation.longitude) / 2 },
            zoom: 10,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
            panControl: false,
            scaleControl: false,
            fullscreenControl: false,
            fullscreenControlOptions: {
              position: window.google.maps.ControlPosition.BOTTOM_CENTER,
            },
          });

          new window.google.maps.Marker({
            position: { lat: startLocation.latitude, lng: startLocation.longitude },
            map,
            title: 'Başlangıç Konumu',
            icon: startIcon,
          });

          new window.google.maps.Marker({
            position: { lat: endLocation.latitude, lng: endLocation.longitude },
            map,
            title: 'Bitiş Konumu',
            icon: finishIcon,
          });

          // Create a DirectionsService instance to use the route service
          const directionsService = new window.google.maps.DirectionsService();
          const directionsRenderer = new window.google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: '#34A34A', // Green color
              strokeOpacity: 1.0,
              strokeWeight: 4
            }
          });

          // Define the request for directions
          const request = {
            origin: new window.google.maps.LatLng(startLocation.latitude, startLocation.longitude),
            destination: new window.google.maps.LatLng(endLocation.latitude, endLocation.longitude),
            travelMode: window.google.maps.TravelMode.DRIVING,
          };

          // Fetch the directions and render the route
          directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              directionsRenderer.setDirections(result);
            } else {
              console.error('Directions request failed due to ' + status);
            }
          });

          console.log('Map initialized:', map);
        } else {
          console.error('Google Maps is not available.');
        }
      })
      .catch((error) => {
        console.error('Error loading Google Maps:', error);
      });
  }, [startLocation, endLocation]);

  return <div ref={mapRef} style={{ height: '400px', width: '100%' }} />;
};

export default MapView; 
import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js'; // Import Supabase client
import './TrackingPage.css';
import logo from '../moveouttr.png'; // Import the logo image
import { ThreeDots } from 'react-loader-spinner'; // Import the ThreeDots loading spinner
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import HCaptcha from '@hcaptcha/react-hcaptcha'; // Import HCaptcha
import MapView from './MapView'; // Import the MapView component

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const TrackingPage = ({ currentLang }) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingResult, setTrackingResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resultsVisible, setResultsVisible] = useState(false); // New state for results visibility
  const navigate = useNavigate(); // Initialize useNavigate
  const [captchaToken, setCaptchaToken] = useState(''); // State for captcha token
  const [captchaError, setCaptchaError] = useState(''); // State for captcha error
  const [captchaVisible, setCaptchaVisible] = useState(true); // State to manage captcha visibility

  const translations = {
    tr: {
      title: 'Sevkiyat Takip',
      placeholder: 'Takip numaranızı giriniz',
      button: 'Sorgula',
      loading: 'Yükleniyor...',
      error: 'Sevkiyat bulunamadı veya bir hata oluştu.',
      enterTracking: 'Lütfen takip numarası giriniz.',
      status: 'Durum',
      location: 'Sürücü Konumu',
      lastUpdate: 'Son Güncelleme',
      deliveryDate: 'Tahmini Teslimat',
      captchaError: 'hCaptcha doğrulaması yapılmadı.',
      startLocation: 'Başlangıç Konumu',
      endLocation: 'Bitiş Adresi',
      startLocationAddress: 'Başlangıç Adresi',
      endLocationAddress: 'Bitiş Adresi'
    },
    en: {
      title: 'Cargo Tracking',
      placeholder: 'Enter your tracking number',
      button: 'Track',
      loading: 'Loading...',
      error: 'Shipment not found or an error occurred.',
      enterTracking: 'Please enter a tracking number.',
      status: 'Status',
      location: 'Location',
      lastUpdate: 'Last Update',
      deliveryDate: 'Estimated Delivery',
      captchaError: 'hCaptcha verification failed.',
      startLocation: 'Start Location',
      endLocation: 'End Location',
      startLocationAddress: 'Start Location Address',
      endLocationAddress: 'End Location Address'
    }
  };

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
    setCaptchaError(''); // Clear any previous error
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!trackingNumber.trim()) {
      setError(translations[currentLang].enterTracking);
      return;
    }

    if (!captchaToken) {
      setCaptchaError(translations[currentLang].captchaError);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      console.log("Sending request to Supabase with tracking number:", trackingNumber);
      const { data, error } = await supabase
        .from('listings')
        .select('*, userName, userPhone, price, description, start_location_address, end_location_address')
        .eq('tracking_code', trackingNumber)
        .single();

      console.log("Response from Supabase:", data, error);

      if (error) throw error;

      if (data) {
        // Set the tracking result based on the response
        setTrackingResult({
          status: data.status.current,
          location: data.end_location_address || 'Unknown',
          lastUpdate: new Date(data.updated_at).toLocaleString(),
          estimatedDelivery: data.delivery_date || 'Unknown',
          userName: data.userName || 'Unknown',
          userPhone: data.userPhone || 'Unknown',
          price: data.price || 'Unknown',
          description: data.description || 'No description available',
          startLocation: JSON.parse(data.start_location),
          endLocation: JSON.parse(data.end_location),
          startLocationAddress: data.start_location_address || 'Unknown',
          endLocationAddress: data.end_location_address || 'Unknown'
        });
        setResultsVisible(true); // Show results with animation
      } else {
        setError(translations[currentLang].error);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(translations[currentLang].error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const trackingPage = document.querySelector('.tracking-page');
      const scrollY = window.scrollY;
      trackingPage.style.backgroundPositionY = `${scrollY * 0.5}px`; // Adjust the multiplier for effect strength
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="tracking-page">
      <div className="tracking-page-container">
        <button onClick={() => navigate('/')} className="back-button">
          <img src={require('../assets/back.png')} alt="Back" className="back-icon" />
        </button>
        <img src={logo} alt="Logo" className="tracking-logo" />
        <h1>{translations[currentLang].title}</h1>
        <form onSubmit={handleSubmit} className="tracking-search-form" aria-label="Tracking Number Form">
          <input
            type="text"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            placeholder={translations[currentLang].placeholder}
            className="tracking-search-input"
            aria-required="true"
            aria-invalid={error ? 'true' : 'false'}
            aria-describedby="error-message"
          />
          <button type="submit" className="tracking-search-button" disabled={loading} aria-busy={loading} aria-label="Track Cargo">
            {loading ? translations[currentLang].loading : translations[currentLang].button}
          </button>
        </form>

        {captchaVisible && !trackingResult && (
          <HCaptcha
            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
            onVerify={handleCaptchaVerify}
            onExpire={() => setCaptchaToken('')}
          />
        )}
        {captchaError && <div className="tracking-error">{captchaError}</div>}

        {loading && <ThreeDots color="#007bff" height={50} width={50} />}

        {error && <div id="error-message" className="tracking-error" role="alert">{error}</div>}

        {trackingResult && !error && (
          <div className={`tracking-results-container ${resultsVisible ? 'visible' : ''}`}>
            {console.log('Tracking Result:', trackingResult)}
            <table className="tracking-info-table">
              <tbody>
                <tr>
                  <td><strong>Durum:</strong></td>
                  <td>{trackingResult.status}</td>
                </tr>
                <tr>
                  <td><strong>Konum:</strong></td>
                  <td>{trackingResult.location}</td>
                </tr>
                <tr>
                  <td><strong>Son Güncelleme:</strong></td>
                  <td>{trackingResult.lastUpdate}</td>
                </tr>
                <tr>
                  <td><strong>Tahmini Teslimat:</strong></td>
                  <td>{trackingResult.estimatedDelivery}</td>
                </tr>
                <tr>
                  <td><strong>Başlangıç Adresi:</strong></td>
                  <td>{trackingResult.startLocationAddress}</td>
                </tr>
                <tr>
                  <td><strong>Bitiş Adresi:</strong></td>
                  <td>{trackingResult.endLocationAddress}</td>
                </tr>
              </tbody>
            </table>
            
            {/* Pass the start and end locations to MapView */}
            <MapView 
              startLocation={{ latitude: trackingResult.startLocation.latitude, longitude: trackingResult.startLocation.longitude }} 
              endLocation={{ latitude: trackingResult.endLocation.latitude, longitude: trackingResult.endLocation.longitude }} 
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackingPage;
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';
import boxesIcon from '../boxes.png';
import homeIcon from '../home.png';

const NotFound = () => {
  // Detect browser language, defaulting to Turkish if not English
  const browserLang = navigator.language.toLowerCase().startsWith('en') ? 'en' : 'tr';
  
  const content = {
    tr: {
      title: '404',
      heading: 'Sayfa Bulunamadı',
      description: 'Aradığınız sayfa mevcut değil veya taşınmış olabilir.',
      button: 'Ana Sayfaya Dön'
    },
    en: {
      title: '404',
      heading: 'Page Not Found',
      description: 'The page you are looking for does not exist or has been moved.',
      button: 'Back to Home'
    }
  };

  const t = content[browserLang];

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <img src={boxesIcon} alt="404" className="not-found-icon" />
        <div className="not-found-title">{t.title}</div>
        <h2>{t.heading}</h2>
        <p>{t.description}</p>
        <Link to="/" className="home-button">
          <img src={homeIcon} alt="home" className="home-icon" />
          {t.button}
        </Link>
      </div>
    </div>
  );
};

export default NotFound; 